import { create } from "zustand";
import { persist } from "zustand/middleware";

import { PersonalInfoSlice } from "./slices/createPersonalInfoSlice";
import { PlanSlice } from "./slices/createPlanSlice";
import { AddOnSlice } from "./slices/createAddOnSlice";
import { ToggleSubcriptionPlanSlice } from "./slices/createToggleSubscriptionPlanSlice";
import { PaymentSlice } from "./slices/createPaymentSlice";
import { ProductSlice } from "./slices/createProductSlice";
import { OrderDataSlice } from "./slices/createOrderDataSlice";
import { ChessboardThemeSlice } from "./slices/createChessboardThemeSlice";
import { StepSlice } from "./slices/createStepSlice";
import { SubmitFormSlice } from "./slices/createSubmitFormSlice";
import { PuzzleStateSlice } from "./slices/createPuzzleStateSlice";
import { RushStateSlice } from "./slices/createRushStateSlice";

import {
  createPersonalInfoSlice,
  createPlanSlice,
  createAddOnSlice,
  createToggleSubscriptionPlanSlice,
  createStepSlice,
  createSubmitFormSlice,
  createPaymentSlice,
  createProductSlice,
  createOrderDataSlice,
  createChessboardThemeSlice,
  createPuzzleStateSlice,
  createRushStateSlice,
} from "./slices";

const useStore = create<
  PersonalInfoSlice &
    PlanSlice &
    AddOnSlice &
    ToggleSubcriptionPlanSlice &
    StepSlice &
    SubmitFormSlice &
    PaymentSlice &
    ProductSlice &
    OrderDataSlice &
    ChessboardThemeSlice &
    PuzzleStateSlice &
    RushStateSlice
>()(
  persist(
    (...a) => ({
      ...createPersonalInfoSlice(...a),
      ...createPlanSlice(...a),
      ...createAddOnSlice(...a),
      ...createToggleSubscriptionPlanSlice(...a),
      ...createStepSlice(...a),
      ...createSubmitFormSlice(...a),
      ...createPaymentSlice(...a),
      ...createProductSlice(...a),
      ...createOrderDataSlice(...a),
      ...createChessboardThemeSlice(...a),
      ...createPuzzleStateSlice(...a),
      ...createRushStateSlice(...a),
    }),
    {
      name: "use-store",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["puzzleState", "rushState"].includes(key),
          ),
        ),
    },
  ),

  // (...a) => ({
  //   ...createPersonalInfoSlice(...a),
  //   ...createPlanSlice(...a),
  //   ...createAddOnSlice(...a),
  //   ...createToggleSubscriptionPlanSlice(...a),
  //   ...createStepSlice(...a),
  //   ...createSubmitFormSlice(...a),
  //   ...createPaymentSlice(...a),
  //   ...createProductSlice(...a),
  //   ...createOrderDataSlice(...a),
  // }),
);

export default useStore;
