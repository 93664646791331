import { StateCreator } from "zustand";

type RushState = {
  correctCount: number;
  wrongCount: number;
  bankSeconds: number;
};

type RushStateSlice = {
  rushState: RushState;
  setRushState: (rushState: RushState) => void;
};

const initialState = {
  correctCount: 0,
  wrongCount: 0,
  bankSeconds: 0,
};

const createRushStateSlice: StateCreator<RushStateSlice> = (set) => ({
  rushState: initialState,
  setRushState: (data) =>
    set((state) => ({
      rushState: { ...state.rushState, ...data },
    })),
});

export default createRushStateSlice;
export type { RushState, RushStateSlice };
